const host = window && window.location && window.location.host
var hostname = window && window.location && window.location.hostname

export const CDN_URL = `https://${host}/`
export const API_ROOT = 'https://data.stage.bookeeapp.com'

export const IMAGE_PATH = 'assets/images/'
export const CDN_PATH = CDN_URL + IMAGE_PATH
export const SEARCH_REDIRECT = ''
export const ENVIRONMENT = 'staging'
export const PROD = false
export const POSTHOG_APIKEY = 'phc_1bJHGr24VQNqQ2aj9d0iqIJ8T9FgoFiQ68ISJlLby6K'
export const WAF_RECAPTCHA =
  'https://5e517f4d6897.ap-south-1.captcha-sdk.awswaf.com/5e517f4d6897/jsapi.js'
